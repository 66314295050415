export const nodeEnv = process.env.REACT_APP_ENV;

let url = '';

switch (nodeEnv) {
  case 'development':
    url = 'https://api.useblanc.com';
    break;
  case 'staging':
    url = 'https://api.useblanc.com';
    break;
  default:
    url = 'https://testflight.useblanc.com';
    break;
}

const BACKEND_URL = url;

export { BACKEND_URL };
