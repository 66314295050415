import { Stream } from '@cloudflare/stream-react';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  createStyles,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Paper,
  Theme,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ArrowBack, DoneAll, DoneOutlined } from '@material-ui/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTimer } from 'use-timer';
import useSWR from 'swr';
import { request } from '../../shared/utils/api';
import Auth from '../../context/Auth';
import DetailsForm from './components/DetailsForm';
import ProjectActions from './components/ProjectActions';
import SourcesGrid from './components/SourcesGrid';
import EditableCell from './components/SourcesGrid/EditableCell';

import Languages from '../../context/Languages';
import {
  ProjectInfoDTO,
  VoicesLangsDTO,
  CopywriterPermissions,
  ProjectInfo,
} from './ProjectDetails.interface';

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const ProjectDetails = () => {
  const classes = useStyles();
  const h = useHistory();
  const { id } = useParams<{ id: string }>();
  const { toLang } = useParams<{ toLang: string }>();
  const { languagesAndVoices } = Languages.useContainer();
  const [admins, setAdmins] = useState<any>(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [showVideo, setShowVideo] = useState(false);
  const [targetStatus, setTargetStatus] = useState(null);
  const [form, setForm] = useState<any>(null);
  const [title, setTitle] = useState<string>('');
  const [backLink, setBackLink] = useState('/');
  const [showIgnoreDialog, setShowIgnoreDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showCompleteDialog, setShowCompleteDialog] = useState(false);
  const [disableInterface, setDisableInterface] = useState(false);
  const [uncompletedPhrases, setUncompletedPhrases] = useState([]);
  const [errors, setErrors] = useState<string | undefined>();
  const [copywriterPermissions, setCopywriterPermissions] =
    useState<CopywriterPermissions>();
  const [projectInfo, setProjectInfo] = useState<ProjectInfo | undefined>();
  const [multispeaker, setMultispeaker] = useState<boolean | undefined>();
  const [isLoading, setLoading] = useState(false);

  const auth = Auth.useContainer();

  const updateStatus = (projectResponse: ProjectInfo) => {
    setVideoUrl(projectResponse?.source_link);
    setTargetStatus(projectResponse?.target_status);
  };

  const checkCopywriterPermissions = (projectData: ProjectInfo) => {
    const currentUsername = auth.name;
    if (auth.staff) {
      setCopywriterPermissions('admin');
    } else if (
      projectData?.source_copywriter === currentUsername &&
      projectData?.source_finished !== true
    ) {
      setCopywriterPermissions('source');
    } else if (
      projectData?.target_copywriter === currentUsername &&
      projectData?.source_finished === true
    ) {
      setCopywriterPermissions('target');
    }
  };

  const getProject = async () => {
    try {
      return await request(`api/projects/${id}/${toLang}`).then(
        (value: ProjectInfoDTO | null) => {
          if (value?.project_info) {
          checkCopywriterPermissions(value?.project_info[0] as ProjectInfo);
          setProjectInfo(value?.project_info[0]);
          updateStatus(value?.project_info[0] as ProjectInfo);
          return value as ProjectInfoDTO;
        }

        return null
        },
      );
    } catch (err) {
      console.log('Error', err);
      return err as any;
    }
  };

  const { data, revalidate: refreshProject } = useSWR<ProjectInfoDTO | null>(
    'get-project',
    getProject,
    {
      revalidateOnMount: true,
      revalidateOnFocus: true,
      refreshInterval: 3000,
      dedupingInterval: 0, // Needed for revalidate to work with refresh interval
      onSuccess: (updatedData) => {
        checkCopywriterPermissions(updatedData?.project_info[0] as ProjectInfo);

        if (
          isLoading &&
          updatedData?.project_info[0].source_finished &&
          updatedData?.source_status === '100'
        ) {
          setLoading(false);
        }
      },
    },
  );

  useEffect(() => {
    if (data) {
      setTitle(projectInfo?.name as string);
      if (auth?.staff) {
        setBackLink(`/orders/${projectInfo?.order_id}`);
      } else {
        setBackLink('/');
      }
    }
  }, [data, projectInfo]);

  useEffect(() => {
    if (!admins) {
      request(`api/get_admins/`).then((r) => {
        setAdmins(r?.admins_list);
      });
    }
  }, []);

  const onFinishSource = (ignore) => {
    request(`api/finish_source/`, {
      method: 'POST',
      body: JSON.stringify({
        ignore,
        project_uuid: id,
        to_lang: toLang,
      }),
    }).then((r) => {
      if (r?.phrases) {
        setUncompletedPhrases(r.phrases);
        setShowIgnoreDialog(true);
      } else if (r.info === 'Success') {
        setShowCompleteDialog(true);
      }
    });
  };

  const onFinishTarget = (ignore) => {
    request(`api/finish_target/`, {
      method: 'POST',
      body: JSON.stringify({
        ignore,
        project_uuid: id,
        to_lang: toLang,
      }),
    }).then((r) => {
      if (r?.phrases) {
        setUncompletedPhrases(r.phrases);
        setShowIgnoreDialog(true);
      } else if (!ignore) {
        setShowConfirmDialog(true);
      } else if (r.info === 'Success') {
        setShowCompleteDialog(true);
      }
    });
  };

  const openProject = () => {
    request(`api/open_project/`, {
      method: 'POST',
      body: JSON.stringify({
        open_projects_with_ids: [
          `${projectInfo?.project_uuid}|${projectInfo?.to_lang}`,
        ],
      }),
    }).then((r) => {
      if (r?.info === 'Projects opened!') {
        setDisableInterface(false);
      }
    });
  };

  useEffect(() => {
    if (projectInfo?.status === 'Closed') {
      setDisableInterface(true);
    }

    if (projectInfo?.multispeaker) {
      setMultispeaker(projectInfo?.multispeaker);
    }
  }, [projectInfo, data]);

  const getIframeLink = () => {
    const link = `https://testflight.useblanc.com/api/web_player?project_uuid=${projectInfo?.project_uuid}`
    // if (projectInfo?.subtitles){
    //   link = `${link}?subtitles=${projectInfo?.subs_link}` ;
    // }
    return link
  };
  return (
    <Box>
      <Box mt={5} display="flex" alignItems="center">
        <Tooltip title="Get back to Projects">
          <Link to={backLink}>
            <IconButton>
              <ArrowBack />
            </IconButton>
          </Link>
        </Tooltip>
        <Box ml={2}>
          <Typography variant="h4">Project details</Typography>
        </Box>
      </Box>

      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Typography variant="h6" color="initial">
            Translating project, please wait or go back to work on other
            projects.
          </Typography>

          <Box mt={2}>
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <>
          {projectInfo && !disableInterface && !isLoading ? (
            <Box mt={3} mb={4}>
              <Paper elevation={5}>
                {data && (
                  <Box p={3}>
                    <Box display="flex" justifyContent="space-between">
                      <Box>
                        <Typography variant="h5">
                          <EditableCell
                            disabled={
                              projectInfo?.status.toLowerCase() === 'done'
                            }
                            content={title || projectInfo?.name}
                            onChange={(e) => {
                              if (title !== e) {
                                setTitle(e);
                                request(`api/edit_project_setup/`, {
                                  method: 'POST',
                                  body: JSON.stringify({
                                    project_uuid: id,
                                    to_lang: projectInfo?.to_lang,
                                    params_to_edit: [['name', e]],
                                  }),
                                });
                              }
                            }}
                          />
                          {/* {projectInfo?.name} */}
                        </Typography>
                        <Typography variant="subtitle2">
                          Status - {projectInfo?.status}{' '}
                          {projectInfo?.status.toLowerCase() === 'done'
                            ? '✅'
                            : '❌'}
                        </Typography>
                      </Box>
                      <ProjectActions
                        done={projectInfo?.status === 'Done'}
                        sourceFinished={projectInfo?.source_finished as boolean}
                        fromLang={projectInfo?.from_lang as string}
                        toLang={projectInfo?.to_lang as string}
                        sourceCopywriter={
                          projectInfo?.source_copywriter as string
                        }
                        targetCopywriter={
                          projectInfo?.target_copywriter as string
                        }
                        refreshProject={refreshProject}
                      />
                    </Box>

                    <Box my={3}>
                      <Divider />
                    </Box>

                    <DetailsForm
                      onChange={(e) => {
                        setForm(e);
                        request(`api/edit_project_setup/`, {
                          method: 'POST',
                          body: JSON.stringify({
                            project_uuid: id,
                            to_lang: e.target,
                            params_to_edit: [
                              ['admin', e.admin],
                              ['copywriter', e.copywriter],
                              ['name', title || projectInfo?.name],
                              ['voice', e.voice],
                              ['lipsync', e.lipSync],
                              ['subtitles', e.subtitles],
                              ['bas', e.bas],
                            ],
                          }),
                        });
                      }}
                      initialValues={{
                        admin: projectInfo?.admin,
                        sourceCopywriter: projectInfo?.source_copywriter,
                        targetCopywriter: projectInfo?.target_copywriter,
                        from: projectInfo?.from_lang,
                        to: projectInfo?.to_lang,
                        voice: projectInfo?.voice,
                        bas: projectInfo?.bas,
                        lipSync: projectInfo?.lipsync,
                        subtitles: projectInfo?.subtitles,
                      }}
                      multispeaker={multispeaker}
                      setMultispeaker={setMultispeaker}
                      done={projectInfo?.status === 'Done'}
                      sourceComplete={projectInfo?.source_finished}
                      data={languagesAndVoices as VoicesLangsDTO}
                      admins={admins}
                      projectId={projectInfo?.project_uuid}
                      refreshProject={refreshProject}
                    />

                    {data?.project_info && (
                      <SourcesGrid
                        voices={languagesAndVoices as VoicesLangsDTO}
                        data={data}
                        copywriterPermissions={copywriterPermissions}
                        multispeaker={multispeaker}
                        refreshProject={refreshProject}
                      />
                    )}

                    {projectInfo?.order_id && (
                      <Box mt={3}>
                        <Divider />
                        <Box>
                          <Grid container spacing={2} justifyContent="flex-end">
                            {(auth.staff &&
                              projectInfo?.source_finished !== true) ||
                            copywriterPermissions === 'source' ? (
                              <Grid item>
                                <Button
                                  disabled={
                                    projectInfo?.source_finished === true ||
                                    projectInfo?.status.toLowerCase() ===
                                      'done' ||
                                    projectInfo?.status.toLowerCase() ===
                                      'generating'
                                  }
                                  startIcon={<DoneAll />}
                                  variant="contained"
                                  color="primary"
                                  onClick={() => onFinishSource(false)}
                                >
                                  Finish source
                                </Button>
                              </Grid>
                            ) : (
                              projectInfo?.source_finished === true && (
                                <Grid item>
                                  <Button
                                    disabled={
                                      auth?.staff
                                        ? false
                                        : (!auth.staff &&
                                            projectInfo?.status.toLowerCase() ===
                                              'done') ||
                                          projectInfo?.status.toLowerCase() ===
                                            'generating'
                                    }
                                    startIcon={<DoneAll />}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => onFinishTarget(false)}
                                  >
                                    Finish target
                                  </Button>
                                </Grid>
                              )
                            )}
                          </Grid>
                        </Box>
                        <Box mt={3}>
                          <Box mb={2} display="flex" alignItems="center">
                            <Typography variant="h6">Video</Typography>
                            <Box ml={3}>
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() => setShowVideo(!showVideo)}
                              >
                                {showVideo ? 'Hide' : 'Show'}
                              </Button>
                            </Box>
                          </Box>
                          {showVideo && (
                            // <Stream controls src={projectInfo?.source_link} />
                            <div style={{ width: '100%', height: '70vh' }}>
                            <iframe
                              src={getIframeLink()}
                              width="100%"
                              height="100%"
                              style={{ border: 'none' }}
                              title="BLANK WebPlayer"
                              allow="clipboard-write; autoplay; fullscreen; encrypted-media"
                              allowFullScreen

                          /></div>
                          //   <WebPlayer
                          //   project={projectInfo}
                          //   src={projectInfo?.source_link}
                          //   originalLang={projectInfo?.from_lang}
                          //   translationLangs={[projectInfo?.to_lang]}
                          //   subtitleLangs={projectInfo?.subtitles}
                          // />
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Paper>

              {/* Ignore Dialog */}
              <Dialog
                fullWidth
                open={showIgnoreDialog}
                onClose={() => setShowIgnoreDialog(false)}
                aria-labelledby="form-dialog-title"
              >
                <DialogContent>
                  <Typography variant="h6">
                    Correct the following blocks, please, so their status is
                    “ok”:
                  </Typography>
                  {uncompletedPhrases &&
                    uncompletedPhrases.map((item) => (
                      <Typography variant="body1" component="h5" key={item}>
                        {item}
                      </Typography>
                    ))}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setShowIgnoreDialog(false)}
                    variant="outlined"
                    color="secondary"
                  >
                    Ok
                  </Button>
                  <Button
                    onClick={() => {
                      setShowIgnoreDialog(false);
                      setShowConfirmDialog(true);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Ignore
                  </Button>
                </DialogActions>
              </Dialog>

              {/* Confirmation Dialog */}
              <Dialog
                fullWidth
                open={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                aria-labelledby="form-dialog-title"
              >
                <DialogContent>
                  <Typography variant="h6" align="center">
                    Are you sure you don't want to edit anything else??{' '}
                  </Typography>
                  <Typography variant="h6" align="center">
                    After that you won’t be able to edit it again!
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setShowConfirmDialog(false)}
                    variant="outlined"
                    color="secondary"
                  >
                    Go back
                  </Button>
                  {projectInfo?.source_finished !== true ? (
                    <Button
                      onClick={() => {
                        setShowConfirmDialog(false);
                        onFinishSource(true);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Yes
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        setShowConfirmDialog(false);
                        onFinishTarget(true);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Yes
                    </Button>
                  )}
                </DialogActions>
              </Dialog>

              {/* Completed Dialog */}
              <Dialog
                fullWidth
                open={showCompleteDialog}
                onClose={() => setShowCompleteDialog(false)}
                aria-labelledby="form-dialog-title"
              >
                <DialogContent>
                  {!errors ? (
                    <>
                      <Typography variant="h6" align="center">
                        Hooray! 🎉🎉🎉
                      </Typography>
                      <Typography variant="h6" align="center">
                        You have finished the project.
                      </Typography>
                      <Typography variant="h6" align="center">
                        Great job, {auth.name}! :)
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="h6" align="center">
                      {errors}
                    </Typography>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      if (
                        !errors &&
                        projectInfo.source_finished !== true &&
                        projectInfo?.source_copywriter ===
                          projectInfo?.target_copywriter
                      ) {
                        setShowCompleteDialog(false);
                        setLoading(true);
                      } else if (!errors && !auth.staff) {
                        h.push('/');
                      } else if (!errors && auth.staff) {
                        setShowCompleteDialog(false);
                        setLoading(true);
                      }
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          ) : (
            <>
              {disableInterface ? (
                <Box
                  mt={2}
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    height: '100%',
                  }}
                >
                  <Typography variant="h4" color="initial">
                    This Project is closed.
                  </Typography>

                  <Typography variant="h5" color="initial">
                    Please re-open this project to edit the project details.
                  </Typography>

                  <Box mt={2}>
                    <ButtonGroup variant="outlined" size="small">
                      <Button
                        startIcon={<DoneOutlined />}
                        color="default"
                        size="small"
                        onClick={openProject}
                      >
                        Re-Open project
                      </Button>
                    </ButtonGroup>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default ProjectDetails;
