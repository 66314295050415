import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Uploader from '../../../../shared/components/Uploader';
import { BACKEND_URL } from '../../../../shared/utils/config';
import { request } from '../../../../shared/utils/api';
import Auth from '../../../../context/Auth';
import {
  MAX_CHUNK_SIZE,
  MAX_UPLOAD_SIZE,
} from '../../../../utils/uploadOptions.constant';
import { CloudDownload } from '@material-ui/icons';
interface Props {
  projectId: string;
  toLang: string;
  fileType: 'video' | 'subtitle';
  title: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (e?: any) => void;
}

const useStyles = makeStyles({
  bold: {
    fontWeight: 600,
  },
});

const CustomUploadDialog: FC<Props> = ({
  projectId,
  toLang,
  fileType,
  title,
  open,
  onClose,
  onSubmit,
}) => {
  const h = useHistory();
  const classes = useStyles();
  const [uploadId, setUploadId] = useState('');
  const { token } = Auth.useContainer();
  const [errors, setErrors] = useState<string | undefined>();
  const [confirmationMessage, setConfirmationMessage] = useState<
    string | undefined
  >();
  const [downloadUrl, setDownloadUrl] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<Boolean>(false);

  const getId = (error: any, file: any) => {
    if (!file?.serverId) {
      setErrors('Problem uploading file, please try again.');
    }

    let extension = file.fileExtension;
    // Check file extension if they are not .srt or .mp4 send error message
    if (fileType === 'subtitle' && extension !== 'srt') {
      setErrors('Subtitles must have an .srt extension');
      return;
    }

    if (fileType === 'video' && extension !== 'mp4') {
      setErrors('Videos must have an .mp4 extension');
      return;
    }

    if (file.fileSize > MAX_UPLOAD_SIZE) {
      setErrors('The file you uploaded is too large, the max file size is 5TB');
      return;
    }
    setIsLoading(true);

    request(`api/custom_file_upload_complete/`, {
      method: 'POST',
      body: JSON.stringify({
        upload_id: file.serverId,
        project_uuid: projectId,
        to_lang: toLang,
        file_type: fileType,
      }),
    }).then((r) => {
      setDownloadUrl(r?.download_url);
      setUploadId(file.serverId);
      setIsLoading(false);
    });
  };

  const uploadSettings = {
    checkValidity: true,
    chunkUploads: true,
    allowMinimumUploadDuration: false,
    chunkSize: 1048576 * 5, // 5MB
    maxParallelUploads: '1',
    onprocessfile: getId,
    onprocessfilerevert: () => {
      setErrors(undefined);
      return;
    },
    name: 'filepond',
    server: {
      url: `${BACKEND_URL}/api/fp`,
      process: {
        url: '/process/',
        headers: {
          Authorization: `JWT ${token}`,
        },
      },
      patch: '/patch/',
      revert: '/revert/',
      fetch: '/fetch/?target=',
    },
  };

  const handleUpload = () => {
    if (confirmationMessage) {
      setErrors(undefined);
      onClose();
    } else if (errors === undefined) {
      setConfirmationMessage(`Successfully uploaded your custom ${fileType}`);
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>
        {confirmationMessage ? confirmationMessage : title}
      </DialogTitle>

      <DialogContent>
        {confirmationMessage ? (
          <a href={downloadUrl} target="_blank">
            <Box sx={{ width: '50%', display: 'inline-flex' }}>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.bold}
              >
                Download Preview
              </Typography>

              <Box ml={1}>
                <CloudDownload style={{ fill: '#fff' }} />
              </Box>
            </Box>
          </a>
        ) : (
          <>
            <Uploader settings={uploadSettings} />

            {errors && (
              <Box>
                <Typography variant="body2" color="error">
                  {errors}
                </Typography>
              </Box>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        {!confirmationMessage && (
          <Button onClick={onClose} variant="outlined" color="secondary">
            Cancel
          </Button>
        )}

        <Button
          onClick={handleUpload}
          variant="contained"
          color="primary"
          disabled={errors !== undefined || downloadUrl === undefined}
        >
          {isLoading ? 'Loading...' : confirmationMessage ? 'Close' : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomUploadDialog;
