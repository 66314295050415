import {
  Box,
  Button,
  IconButton,
  ButtonGroup,
  CircularProgress,
  debounce,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  PanToolOutlined,
  Refresh,
  PlayCircleFilled,
  PauseCircleFilled,
  CancelOutlined,
} from '@material-ui/icons';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { request } from '../../../../shared/utils/api';
import {
  CopywriterPermissions,
  ProjectInfoDTO,
  VoicesLangsDTO,
} from '../../ProjectDetails.interface';
import EditableCell from './EditableCell';
import Auth from '../../../../context/Auth';
import { BACKEND_URL } from '../../../../shared/utils/config';
import CustomUploadDialog from '../../../Order/components/CustomUploadDialog';

const Transition = React.forwardRef(function Transition(
  // eslint-disable-next-line react/require-default-props
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  voices: VoicesLangsDTO;
  data: ProjectInfoDTO;
  multispeaker: boolean | string | undefined;
  copywriterPermissions: CopywriterPermissions;
  refreshProject: () => void;
}

type ColumnToggle = 'source' | 'target' | 'both' | undefined;

const getTime = (time) => {
  const minutes = Math.floor(time / 60000);
  const seconds = +((time % 60000) / 1000).toFixed(0);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

const useStyles = makeStyles(() => ({
  container: {
    maxHeight: 600,
  },
  MuiTableCellBody: {
    textAlign: 'unset',
    width: '500px',
    maxWidth: '500px',
    verticalAlign: 'unset',
    whiteSpace: 'pre-line',
    textCombineUpright: 'digits',
    wordBreak: 'break-all',
  },
  Warning: {
    color: 'orange',
  },
  Success: {
    color: 'green',
  },
  Disabled: {
    pointerEvents: 'none',
    opacity: 0.4,
  },
}));

const SourcesGrid: FC<Props> = ({
  voices,
  copywriterPermissions,
  data,
  multispeaker,
  refreshProject,
}) => {
  const auth = Auth.useContainer();
  const classes = useStyles();

  const { id } = useParams<{ id: string }>();
  const { toLang } = useParams<{ toLang: string }>();
  const [isLoading, setLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState([]) as any;
  const [reloadAudioKey, setReloadAudio] = useState(0);
  const [columnToggle, setColumnToggle] = useState<ColumnToggle>();

  const [showCustomUploadDialog, setShowCustomUploadDialog] = useState<
    'subtitles' | 'video' | undefined
  >();

  const [dialogTitle, setDialogTitle] = useState<string>('');

  const [targetVoices, setTargetVoices] = useState<string[]>([]);
  // const targetVoices = [] as any;

  useEffect(() => {
    const targetLangs = voices.languages.filter((i) => i.to);
    const tVoices = [] as string[];

    targetLangs
      .filter(
        (i) =>
          i.lang_short_name.slice(0, 2) ===
          voices.languages
            .find((i) => i.lang_name === toLang)
            ?.lang_short_name.slice(0, 2),
      )
      .map((item) => item.voices.map((voice) => tVoices.push(voice)));
    tVoices.sort();

    setTargetVoices(tVoices);
  }, [voices]);

  const editTargetCell = (text, idx) => {
    setLoading(true);
    request(`api/update_target_phrase/`, {
      method: 'POST',
      body: JSON.stringify({
        project_uuid: id,
        to_lang: toLang,
        index: idx,
        new_phrase: text,
      }),
    })
      .then(() => {
        refreshProject();
        setLoading(false);
      })
      .catch((err) => {
        console.log('Error', err);
      });
  };

  const editSourceCell = useCallback(
    (text, idx) => {
      // setLoading(true);
      request(`api/update_source_phrase/`, {
        method: 'POST',
        body: JSON.stringify({
          project_uuid: id,
          to_lang: toLang,
          index: idx,
          new_phrase: text,
        }),
      }).then(() => {
        refreshProject();
        setLoading(false);
      });
    },
    [setLoading, id],
  );

  const updateCell = useCallback(
    debounce((text: string, idx: number, type: 'source' | 'target') => {
      if (text !== null && idx>=0) {
        if (type === 'source') {
          if (text !== data?.workarea?.[idx]?.source) {
            // Remove extra whitespace at the end of the line
            let formattedText = text.replace(/\s+$/gm, '');

            // add a `.` if there is not one already to avoid breaking the project
            if (
              !formattedText.endsWith('.') &&
              !formattedText.endsWith('?') &&
              !formattedText.endsWith('!')
            ) {
              formattedText += '.';
            }

            editSourceCell(formattedText, idx);
          }
        } else if (type === 'target') {
          editTargetCell(text, idx);
        }
      }
    }, 1000),
    [],
  );

  const editVoice = (voice: string, idx: number, target: string) => {
    setLoading(true);
    request(`api/update_phrase_voice/`, {
      method: 'POST',
      body: JSON.stringify({
        project_uuid: id,
        to_lang: toLang,
        index: idx,
        voice,
      }),
    }).then(() => {
      editTargetCell(target, idx);
      setReloadAudio(reloadAudioKey + 1);
      refreshProject();
    });
  };

  const setPlay = (el, idx) => {
    const allAudioElements = document.querySelectorAll('audio');

    allAudioElements.forEach((element: HTMLAudioElement) => {
      if (element.id !== el) {
        element.pause();
        element.currentTime = 0;
      }
    });

    const currentAudioEl = document.getElementById(el) as HTMLAudioElement;

    // Assign a random query string to ensure the audio file does not play the cached version
    if (currentAudioEl) {
      if (currentAudioEl.firstChild) {
        const sourceEl = currentAudioEl.firstChild as HTMLSourceElement;

        if (sourceEl.src.includes('?rnd')) {
          const newSrc = sourceEl.src.replace(
            /(?:\?rnd=[0-9]*)/,
            `?rnd=${String(Math.floor(Math.random() * 7232423))}`,
          );

          sourceEl.src = newSrc;
        } else {
          sourceEl.src = `${sourceEl.src}?rnd=${String(
            Math.floor(Math.random() * 7232423),
          )}`;
        }
      }

      currentAudioEl.load();

      currentAudioEl.play();
      setIsPlaying([el]);
    }
  };

  const setPause = (el, idx) => {
    const currentAudio = document.getElementById(el) as HTMLAudioElement;

    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }
    setIsPlaying([]);
  };

  const buildAudioSrc = (src: string) => {
    const formattedSrc = src.replace(/\/home|\/gandalf|\/WebApp|\/jay/gm, '');
    return `${BACKEND_URL}${formattedSrc}`;
  };

  const handleUploadCustomSubtitles = () => {
    setDialogTitle('Upload your custom subtitles.');
    setShowCustomUploadDialog('subtitles');
  };

  const handleUploadCustomVideo = () => {
    setDialogTitle('Upload your custom video.');
    setShowCustomUploadDialog('video');
  };

  useEffect(() => {
    setColumnToggle(
      auth.staff ? 'both' : (copywriterPermissions as ColumnToggle),
    );
  }, [auth, copywriterPermissions]);

  useEffect(() => {
    if (data?.workarea?.length > 0 && multispeaker === false) {
      data.workarea.forEach((phrase, index) => {
        if (phrase.voice !== data?.project_info?.[0].voice) {
          editVoice(
            data?.project_info?.[0].voice as string,
            index,
            phrase.target,
          );
        }
      });
    }
  }, [multispeaker]);

  return (
    <div className={isLoading ? classes.Disabled : ''}>
      {showCustomUploadDialog && (
        <CustomUploadDialog
          projectId={id}
          toLang={toLang}
          fileType={showCustomUploadDialog === 'video' ? 'video' : 'subtitle'}
          title={dialogTitle}
          open={!!showCustomUploadDialog}
          onClose={() => setShowCustomUploadDialog(undefined)}
          onSubmit={() => {
            setShowCustomUploadDialog(undefined);
          }}
        />
      )}

      <Box mt={1}>
        <Box mb={2} mt={2} display="flex" justifyContent="space-between">
          {data?.project_info?.[0]?.status === 'Re-opened' ? (
            <ButtonGroup variant="text">
              <Button onClick={() => handleUploadCustomVideo()}>
                Upload Custom Video
              </Button>

              <Button onClick={() => handleUploadCustomSubtitles()}>
                Upload Custom Subtitles
              </Button>
            </ButtonGroup>
          ) : (
            // Placeholder to take up a column
            <Box />
          )}

          <ButtonGroup variant="contained" color="primary">
            <Button
              disabled={columnToggle === 'source' && !auth.staff}
              onClick={() => setColumnToggle('source')}
            >
              Source
            </Button>
            <Button
              disabled={
                (columnToggle === 'target' ||
                  copywriterPermissions !== 'target') &&
                !auth.staff
              }
              onClick={() => setColumnToggle('target')}
            >
              Target
            </Button>
            <Button
              disabled={
                (columnToggle === 'both' ||
                  copywriterPermissions !== 'target') &&
                !auth.staff
              }
              onClick={() => setColumnToggle('both')}
            >
              Both
            </Button>
          </ButtonGroup>
        </Box>

        {data?.workarea.length ? (
          <TableContainer
            className={classes.container}
            component={Paper}
            elevation={5}
          >
            <Table stickyHeader size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>№</TableCell>
                  <TableCell>Start</TableCell>
                  <TableCell>End</TableCell>
                  <TableCell>St</TableCell>

                  {(data.project_info[0].multispeaker === true ||
                    multispeaker) &&
                    data?.source_status === '100' && (
                      <TableCell>Voice</TableCell>
                    )}
                  {(columnToggle === 'source' || columnToggle === 'both') && (
                    <TableCell>Source</TableCell>
                  )}
                  {(columnToggle === 'target' || columnToggle === 'both') && (
                    <TableCell>Target</TableCell>
                  )}
                  {data.project_info[0].source_finished === true && (
                    <TableCell>Playback</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.workarea.map((row, index) => (
                  <TableRow
                    hover
                    key={`${row.from_time}|${row.to_time}|${row.phrase_uuid}`}
                  >
                    <TableCell>{index + 1}</TableCell>

                    {/* Start Time */}
                    <TableCell component="th" scope="row">
                      {getTime(row.from_time)}
                    </TableCell>

                    {/* End time */}
                    <TableCell>{getTime(row.to_time)}</TableCell>

                    {/* Status */}
                    <TableCell>
                      {row.target_phrase_status && (
                        <Box
                          maxWidth="6em"
                          sx={{ fontSize: '0.875rem' }}
                          className={
                            row.target_phrase_status === 'ok'
                              ? classes.Success
                              : classes.Warning
                          }
                        >
                          <Typography noWrap>
                            {row.target_phrase_status}
                          </Typography>
                        </Box>
                      )}
                    </TableCell>

                    {/* Voice */}
                    {(data.project_info[0].multispeaker === true ||
                      multispeaker) &&
                      data?.source_status === '100' &&
                      row?.source !== null && (
                        <TableCell width={25}>
                          <Select
                            value={row.voice}
                            disabled={
                              data.project_info[0].source_finished !== true ||
                              !auth.staff
                            }
                            placeholder="Voice"
                            onChange={(e) => {
                              editVoice(
                                e.target.value as string,
                                row.index,
                                row.target,
                              );
                            }}
                          >
                            {targetVoices.map((item) => (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                      )}

                    {/* Source */}
                    {(columnToggle === 'source' || columnToggle === 'both') &&
                      row.source !== null && (
                        <TableCell
                          className={classes.MuiTableCellBody}
                          style={{ minWidth: '300px', flex: 1 }}
                        >
                          <EditableCell
                            // key={row.source}
                            content={row.source}
                            disabled={
                              data.project_info[0].source_finished === true ||
                              (!auth.staff &&
                                copywriterPermissions !== 'source')
                            }
                            index={row.index}
                            type="source"
                            onChange={updateCell as any}
                            isLoading={isLoading}
                          />
                        </TableCell>
                      )}

                    {/* Target */}
                    {(columnToggle === 'target' || columnToggle === 'both') &&
                      row?.target !== null && (
                        <TableCell
                          style={{
                            minWidth: '300px',
                            flex: 1,
                          }}
                          className={classes.MuiTableCellBody}
                        >
                          <EditableCell
                            // key={row.target}
                            content={row.target}
                            disabled={
                              (!auth.staff &&
                                copywriterPermissions !== 'target') ||
                              (auth.staff &&
                                data?.project_info[0].source_finished !== true)
                            }
                            index={row.index}
                            type="target"
                            onChange={(value) =>
                              editTargetCell(value, row.index)
                            }
                            isLoading={isLoading}
                          />
                        </TableCell>
                      )}

                    {/* Playback */}
                    {(auth.staff || copywriterPermissions === 'target') &&
                      data.project_info[0].source_finished === true &&
                      (row.target_phrase_filepath !== null &&
                      row.initial_target !== null ? (
                        <TableCell width={25}>
                          <audio
                            preload="none"
                            key={reloadAudioKey}
                            id={`${row.index}_wav`}
                            onEnded={() => {
                              setIsPlaying([]);
                            }}
                          >
                            <source
                              src={buildAudioSrc(row.target_phrase_filepath)}
                              type="audio/wav"
                            />
                            Your browser does not support the audio element.
                          </audio>

                          {isPlaying.includes(`${row.index}_wav`) ? (
                            <IconButton
                              color="secondary"
                              aria-label="Pause"
                              onClick={() => {
                                setPause(`${row.index}_wav`, row.index);
                              }}
                            >
                              <PauseCircleFilled />
                            </IconButton>
                          ) : (
                            <IconButton
                              color="primary"
                              aria-label="Play"
                              onClick={() => {
                                setPlay(`${row.index}_wav`, row.index);
                              }}
                            >
                              <PlayCircleFilled />
                            </IconButton>
                          )}
                        </TableCell>
                      ) : (
                        <TableCell />
                      ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            py={5}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default SourcesGrid;
